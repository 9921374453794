module.exports = [{
      plugin: require('/Users/leerichardson/workspace/contact-me/node_modules/gatsby-plugin-jss/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/leerichardson/workspace/contact-me/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/leerichardson/workspace/contact-me/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Poppins:bold"]}},
    },{
      plugin: require('/Users/leerichardson/workspace/contact-me/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
